function tourneesController($translate, $state, $scope, WS, $window, $sce) {
  'ngInject';
  const vm = this;

  vm.filter = {};
  vm.mapstunisie_latitude = '34.770981';
  vm.mapstunisie_longitude = '10.702299';
  vm.days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche'
  ];

  vm.trustHtml = function (html) {
    return $sce.trustAsHtml(html);
  };


  vm.getRouteWithDepot = function () {
    WS.get('routing/getRouteWithDepot')
      .then(function (response) {
        vm.routeswithdepot = response.data;
        $scope.$apply();
        /*$('.table_routes').DataTable({
                "language": DatatableLang
            });*/
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectUsers = function () {
    WS.get('users/selectUsers', vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.selectusers = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.findTournees = function () {

    console.log(vm.filter)
    WS.post('tournees/findTournees', vm.filter).then(
      function (response) {

        if (vm.filter.commercial_codes.length > 0) {
          vm.list_users = vm.selectusers.filter(user => vm.filter.commercial_codes.includes(user.code))
        } else {
          vm.list_users = vm.selectusers;
        }
        vm.all_days = response.data.all_days;

        vm.tournees = response.data.tournees;
        vm.visites = response.data.visites;

        console.log(vm.all_days, vm.list_users)


        /**
         * start traitement trie date
         */
        if (vm.filter.trie == "date") {
          var trs = ``;
          _.each(vm.all_days, (day, indexDay) => {
            _.each(vm.list_users, (user, indexUser) => {

              var keyTourneD = day.date + '_' + user.depot_code;
              var keyTourneU = day.date + '_' + user.code;
              var tournee = vm.tournees[keyTourneD] ? vm.tournees[keyTourneD] : [];

              //frequence
              var frequences = _.map(tournee, 'frequence');
              frequences = [...new Set(frequences)]; // Remove duplicates

              //codes tournee
              var codesT = _.map(tournee, 'code');
              codesT = [...new Set(codesT)]; // Remove duplicates

              //client codes
              var client_codesTournee = _.map(tournee, 'client_code');
              client_codesTournee = [...new Set(client_codesTournee)]; // Remove duplicates

              /**
               * Visites , BLS, AVOIR, COMMANDE
               */
              var visitesDay = vm.visites[keyTourneU] ? vm.visites[keyTourneU] : [];

              var all_types = _.pluck(visitesDay, 'types');
              // Flatten the array by splitting each string into individual elements
              all_types = _.flatten(_.map(all_types, function (item) {
                return item.split(', ');
              }));

              // Count the occurrences of 'bl' and 'commande'
              var countBl = _.filter(all_types, function (item) {
                return item === 'bl';
              }).length;

              var countCommande = _.filter(all_types, function (item) {
                return item === 'commande';
              }).length;

              var countAvoir = _.filter(all_types, function (item) {
                return item === 'avoir';
              }).length;

              /**
               * PLUCK CLIENT_CODE FROM VISITES
               */

              var CLS_VISITES = _.pluck(visitesDay, 'client_code');
              var v_cls_in_tournee = 0;
              var v_cls_out_tournee = 0;
              _.each(CLS_VISITES, (client_code) => {
                if (_.contains(client_codesTournee, client_code)) {
                  v_cls_in_tournee++;
                } else {
                  v_cls_out_tournee++;
                }
              })

              var taux_succes_tournee = client_codesTournee.length > 0 ? v_cls_in_tournee * 100 / client_codesTournee.length : 0
              taux_succes_tournee = taux_succes_tournee.toFixed(2);

              var taux_succes_visites = client_codesTournee.length > 0 ? visitesDay.length * 100 / client_codesTournee.length : 0
              taux_succes_visites = taux_succes_visites.toFixed(2);
              trs += `<tr>
                                <td class="text-center">${day.date}</td>
                                <td class="text-center">${day.day_name}</td>
                                <td class="text-center">${frequences.length > 0 ? frequences.join(", ") : ''}</td>
                                <td class="text-center">${user.user}</td>
                                <td class="text-center">${codesT.length > 0 ? codesT.join(", ") : ''}</td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center">${tournee.length}</td>
                                <td class="text-center">${v_cls_in_tournee}</td>
                                <td class="text-center">${v_cls_out_tournee}</td>
                                <td class="text-center"></td>
                                <td class="text-center">${vm.visites[keyTourneU] ? vm.visites[keyTourneU].length : ''}</td>
                                <td class="text-center">${countBl}</td>
                                <td class="text-center">${countAvoir}</td>
                                <td class="text-center">${countCommande}</td>
                                <td class="text-center">${taux_succes_tournee} %</td>
                                <td class="text-center">${taux_succes_visites} %</td>
                            </tr>`;
            })
          })
        }


        /**
         * start traitement trie commercial
         */
        if (vm.filter.trie == "commercial") {
          var trs = ``;
          _.each(vm.list_users, (user, indexUser) => {
            _.each(vm.all_days, (day, indexDay) => {

              var keyTourneD = day.date + '_' + user.depot_code;
              var keyTourneU = day.date + '_' + user.code;
              var tournee = vm.tournees[keyTourneD] ? vm.tournees[keyTourneD] : [];

              //frequence
              var frequences = _.map(tournee, 'frequence');
              frequences = [...new Set(frequences)]; // Remove duplicates

              //codes tournee
              var codesT = _.map(tournee, 'code');
              codesT = [...new Set(codesT)]; // Remove duplicates

              //client codes
              var client_codesTournee = _.map(tournee, 'client_code');
              client_codesTournee = [...new Set(client_codesTournee)]; // Remove duplicates

              /**
               * Visites , BLS, AVOIR, COMMANDE
               */
              var visitesDay = vm.visites[keyTourneU] ? vm.visites[keyTourneU] : [];

              var all_types = _.pluck(visitesDay, 'types');
              // Flatten the array by splitting each string into individual elements
              all_types = _.flatten(_.map(all_types, function (item) {
                return item.split(', ');
              }));

              // Count the occurrences of 'bl' and 'commande'
              var countBl = _.filter(all_types, function (item) {
                return item === 'bl';
              }).length;

              var countCommande = _.filter(all_types, function (item) {
                return item === 'commande';
              }).length;

              var countAvoir = _.filter(all_types, function (item) {
                return item === 'avoir';
              }).length;

              /**
               * PLUCK CLIENT_CODE FROM VISITES
               */

              var CLS_VISITES = _.pluck(visitesDay, 'client_code');
              var v_cls_in_tournee = 0;
              var v_cls_out_tournee = 0;
              _.each(CLS_VISITES, (client_code) => {
                if (_.contains(client_codesTournee, client_code)) {
                  v_cls_in_tournee++;
                } else {
                  v_cls_out_tournee++;
                }
              })

              var taux_succes_tournee = client_codesTournee.length > 0 ? v_cls_in_tournee * 100 / client_codesTournee.length : 0
              taux_succes_tournee = taux_succes_tournee.toFixed(2);

              var taux_succes_visites = client_codesTournee.length > 0 ? visitesDay.length * 100 / client_codesTournee.length : 0
              taux_succes_visites = taux_succes_visites.toFixed(2);

              trs += `<tr>
                                <td class="text-center">${day.date}</td>
                                <td class="text-center">${day.day_name}</td>
                                <td class="text-center">${frequences.length > 0 ? frequences.join(", ") : ''}</td>
                                <td class="text-center">${user.user}</td>
                                <td class="text-center">${codesT.length > 0 ? codesT.join(", ") : ''}</td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center">${tournee.length}</td>
                                <td class="text-center">${v_cls_in_tournee}</td>
                                <td class="text-center">${v_cls_out_tournee}</td>
                                <td class="text-center"></td>
                                <td class="text-center">${vm.visites[keyTourneU] ? vm.visites[keyTourneU].length : ''}</td>
                                <td class="text-center">${countBl}</td>
                                <td class="text-center">${countAvoir}</td>
                                <td class="text-center">${countCommande}</td>
                                <td class="text-center">${taux_succes_tournee} %</td>
                                <td class="text-center">${taux_succes_visites} %</td>
                        </tr>`;
            })
          })
        }


        vm.tbodys = vm.trustHtml(trs);

        $scope.$apply();
      },
      function (error) {
        console.log(error);
      });
  }


  /**
   * Interface add Tournees
   */

  vm.clientsLayers = [];
  vm.stock_all_layers = []
  vm.layers_json = []
  vm.layers_params = []



  vm.initMapsTournees = () => {
    console.log('init initMapsTournees')
    // vm.current = false;
    vm.center_latitude = vm.mapstunisie_latitude;
    vm.center_longitude = vm.mapstunisie_longitude;
    $('#container_tournees').empty();
    $('#container_tournees').append('<div style="width: 100%; height: 640px" id="maps_tournees"></div>');

    vm.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    vm.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
    vm.osm = L.tileLayer(vm.osmUrl, {
      maxZoom: 20,
      attribution: vm.osmAttrib
    });
    //console.log('from maps : ', vm.getpays)
   vm.map = new L.Map('maps_tournees', {
      center: new L.LatLng(vm.center_latitude, vm.center_longitude),
      zoom: 7,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 'topright'
      }
    });

    vm.osm.addTo(vm.map);
    //add poligones :
    var count_layer = 0;
    _.each(vm.clientsLayers, (element, index) => {

      if (element.group_id != null && element.layer_polygon.length>=3) {
        count_layer++;
        vm.paramsdrawitem = {};
        vm.paramsdrawitem.id = element.group_id;
        vm.paramsdrawitem.total_sales = element.total_sales;

        console.log(element.line_length, element.polygon_area);

        vm.paramsdrawitem.line_length = element.line_length.toFixed(3) + ' kM';
        vm.paramsdrawitem.polygon_area = element.polygon_area.toFixed(3) + ' kM²';

        vm.paramsdrawitem.color = (element.color) ? element.color : '#3388ff';
        vm.paramsdrawitem.onEachFeature = function (feature, layer) {
          layer.bindPopup('<pre>' + element.group_id + '</pre>');
          //layer._leaflet_id = element.id;
        }
        vm.current_layer = {
          type: "Feature",
          geometry : {
            type: "Polygon",
            coordinates: [element.layer_polygon]
          }
        }
        // vm.current_layer = JSON.parse(vm.current_layer);
        console.log('current_layer', vm.current_layer)
        vm.drawnItems = L.geoJson(vm.current_layer, vm.paramsdrawitem).addTo(vm.map);
        vm.stock_all_layers.push(vm.drawnItems);
        vm.layers_json.push(vm.current_layer);
        vm.layers_params.push(vm.paramsdrawitem);


        if (!vm.bounds) {
          vm.bounds = L.latLngBounds(vm.drawnItems.getBounds());
        } else {
          vm.bounds.extend(vm.drawnItems.getBounds());
        }
      }
    })

    //vm.map.setView([vm.center_latitude, vm.center_longitude], 7);





    setTimeout(function () {
      vm.map.invalidateSize();
      console.log('initialized');
    }, 3000);
  }



  vm.PY_groupClients = function () {
    WS.getData('tournees/PY_groupClients', {
      min_chiffre : vm.filter.min_chiffre,
      max_chiffre : vm.filter.max_chiffre
    })
      .then(function (response) {
        if (response.status == 200) {
          vm.clientsLayers = response.data;

          $scope.$apply();
          vm.initMapsTournees();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };
}
export default {
  name: 'tourneesController',
  fn: tourneesController
};
